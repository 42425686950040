import FModal from '../../components/FModal'
import FFormWrapper from '../../components/FFormWrapper'
import FButton from '../../components/FButton'
import FIconWrapper from '../../components/FIconWrapper'
import { axiosInstance } from '../../axios/requister'
import { useState, useContext } from 'react'
import ESpinner from '../../components/ESpinner'
import { toast } from 'react-toastify'
import { UserContext } from '../../components/UserContext'
import { MdDeleteOutline } from 'react-icons/md'


const DeleteUserModal = ({ openDeleteModal, setOpenDeleteModal }) => {

  const [isLoaded, setIsLoaded] = useState(false)
  const { logout } = useContext(UserContext)

  const handleDelete = () => {
    setIsLoaded(true)
    axiosInstance
      .delete('/authentication/base-user/')
      .then(() => {
        setIsLoaded(false)
        setOpenDeleteModal(false)
        logout()
        setTimeout(() => {
          toast.success('Account deleted successfully')
        }, 1000)
      })

      .catch((err) => {
        setIsLoaded(false)
        setOpenDeleteModal(false)
        toast.error(err.response.data.details)
      })
  }

  return (
    <FModal
      title={'Delete Your Account'}
      isOpen={openDeleteModal}
      setIsOpen={() => {
        setOpenDeleteModal(false)
      }}
    >
      <FFormWrapper>
        <div>
          <div className="flex flex-row">
            <span className="my-auto hidden pr-2 text-5xl lg:block">⚠️</span>
            <div className="ml-2 flex flex-col gap-2">
              <div className="whitespace-nowrap  text-xl font-semibold text-gray-700">
                You are about to delete your account
              </div>
              <div className="text-gray-600">
                {` You are able to delete your if you are not a manager of any company or your account is not linked to any shipment`}
              </div>
            </div>
          </div>
        </div>
      </FFormWrapper>

      <div className={'flex w-full flex-row-reverse gap-1'}>
        <FButton
          onClick={() => {
            handleDelete()
          }}
          btnType={'danger'}
        >

          <FIconWrapper>
          <MdDeleteOutline className="mr-1" size={20} /> 
            Delete
            <ESpinner isVisible={isLoaded} />
          </FIconWrapper>
        </FButton>
        <FButton
          btnType={'secondary'}
          onClick={() => {
            setOpenDeleteModal(false)
          }}
        >
          <FIconWrapper>Cancel</FIconWrapper>
        </FButton>
      </div>
    </FModal>
  )
}
export default DeleteUserModal

