// eslint-disable-next-line
import { Wrapper } from '@googlemaps/react-wrapper'

export const addSingleMarkers = ({ locations, map }) =>
  locations.map(
    (position) =>
      new window.google.maps.Marker({
        position,
        map,
      })
  )

const GoogleMapsWrapper = ({ children }) => {
  const apiKey = 'AIzaSyDm3eo5VxOl5tgdZYpaAFyKiQZd57rGFXY'
  if (!apiKey) {
    return <div>Cannot display the map: google maps api key missing</div>
  }

  return (
    <Wrapper apiKey={apiKey} libraries={['places', 'routes']}>
      {children}
    </Wrapper>
  )
}

export default GoogleMapsWrapper
